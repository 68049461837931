import React from 'react'

import { Badge, Lead, Paragraph } from '@te-digi/styleguide'

import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { Section } from '../../components/Section'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="Lead"
    components={[{ component: Lead }]}
  >
    <Section>
      <Paragraph>
        <Badge>18px, 600</Badge>
      </Paragraph>
      <Playground>
        <Lead noMargin>
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Sed posuere
          interdum sem. Quisque ligula eros ullamcorper quis, lacinia quis
          facilisis sed sapien. Mauris varius diam vitae arcu. Sed arcu lectus
          auctor vitae, consectetuer et venenatis eget velit.
        </Lead>
      </Playground>
      <Playground format="html">
        <p className="lead mb-0">
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Sed posuere
          interdum sem. Quisque ligula eros ullamcorper quis, lacinia quis
          facilisis sed sapien. Mauris varius diam vitae arcu. Sed arcu lectus
          auctor vitae, consectetuer et venenatis eget velit.
        </p>
      </Playground>
    </Section>
  </Content>
)

export default Page
